import { Box, Divider, Link, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { string } from "yup";
import ScaleFadeAnimation from "../../animations/ScaleFadeAnimation";
import { namespaces } from "../../config/i18n/i18n.constants";
import VideoGallery from "../../videoGallery/VideoGallery";
import Gallery from "../gallery/Gallery";
import { IService } from "./Services";

type Props = {
  service: IService;
  hideCoordinates: boolean;
  documentsUrl: string;
  imagesUrl: string;
  videosUrl: string;
};

export default function Service({
  service,
  documentsUrl,
  imagesUrl,
  videosUrl,
  hideCoordinates = false,
}: Props) {
  const { t, i18n } = useTranslation(namespaces.pages.services);
  return (
    <ScaleFadeAnimation>
      <Box paddingBottom={4}>
        <Box textStyle="h2">{service.siteName[i18n.language]}</Box>
        {!service.declarationOfPerformanceUrl &&
          !service.email &&
          !service.images &&
          !service.latitude &&
          !service.longitude &&
          !service.phone &&
          !service.pricelistUrl &&
          !service.siteAddress && <Text>{t("common:excuse")}</Text>}
        <Box paddingLeft={4}>
          {service.pricelistUrl && (
            <Box>
              <Link
                download={true}
                href={documentsUrl + service.pricelistUrl}
                variant="default"
              >
                {t("service.priceList")}
              </Link>
            </Box>
          )}
          {service.declarationOfPerformanceUrl && (
            <Box>
              <Link
                variant="default"
                download={true}
                href={documentsUrl + service.declarationOfPerformanceUrl}
              >
                {t("service.declarationOfPerformance")}
              </Link>
            </Box>
          )}
          {service.siteAddress && (
            <Text>
              {t("common:address")} {service.siteAddress[i18n.language]}
            </Text>
          )}
          {service.phone && (
            <Text>
              {t("common:phone")} {service.phone}
            </Text>
          )}
          {service.email && (
            <Text>
              {t("common:email")} {service.email}
            </Text>
          )}
          {!hideCoordinates && service.latitude && service.longitude && (
            <Text>
              {t("service.gps")} {parseFloat(service.longitude.toString())}
              {", "}
              {parseFloat(service.latitude.toString())}
            </Text>
          )}
        </Box>
        {service.images && (
          <Box paddingTop={2}>
            <Gallery
              images={service.images.map((image) => imagesUrl + image)}
            />
          </Box>
        )}
        {service.images && service.videos && (
          <Divider marginTop={6} borderColor="brand.500" />
        )}
        {service.videos && (
          <Box paddingTop={2}>
            <VideoGallery
              videos={service.videos.map((video) => videosUrl + video)}
            />
          </Box>
        )}
      </Box>
    </ScaleFadeAnimation>
  );
}
