import { Box, Center, Heading } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ScaleFadeAnimation from "../../animations/ScaleFadeAnimation";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../config/i18n/i18n.constants";
import Document from "../concreteBarriers/Document";
import Nav from "../../navigation/Nav";
import { BottomRightInfoBlock } from "../../infoblock/BottomRightInfoBlock";

export interface IDocument {
  url: string;
  text: { [key: string]: string };
}

export default function ConcreteBarriers() {
  const { t, i18n } = useTranslation(namespaces.pages.barriers);
  const [documents, setDocuments] = useState<Array<IDocument>>([]);
  useEffect(() => {
    document.title = "UNI-CITY Kft. | " + t("title");
    axios
      .get("./assets/barriers/barriers.json", {
        headers: {
          pragma: "no-cache",
          "cache-control": "no-cache",
        },
      })
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        setDocuments(response["documents"]);
      });
  }, [t, i18n.language]);
  return (
    <>
      <Nav page="concrete_barriers" />
      <Center paddingBottom={16}>
        <Box width="80%">
          <ScaleFadeAnimation>
            <Heading textStyle="h1">{t("productionOfElements")}</Heading>
          </ScaleFadeAnimation>

          <ScaleFadeAnimation>
            <Center paddingTop={4} paddingBottom={4}>
              <video controls src="/assets/barriers/videos/tereloelem.mp4" />
            </Center>
          </ScaleFadeAnimation>
          <ScaleFadeAnimation>
            {documents.map((document, index) => {
              return <Document document={document} key={index} />;
            })}
          </ScaleFadeAnimation>
        </Box>
      </Center>
      <BottomRightInfoBlock />
    </>
  );
}
