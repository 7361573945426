import { ComponentStyleConfig } from "@chakra-ui/react";
import { darken } from "@chakra-ui/theme-tools";

export const ButtonStyles: ComponentStyleConfig = {
  baseStyle: {},
  sizes: {},
  variants: {
    secondary: {
      bg: "transparent",
      color: "brand.500",
      borderColor: "brand.500",
      border: "1px",
      _hover: {
        borderColor: darken("brand.500", 40),
        color: darken("brand.500", 40),
      },
    },
    primary: {
      bg: "brand.900",
      color: "brand.500",
      borderColor: "brand.500",
      border: "1px",
      _hover: {
        color: darken("brand.500", 20),
        borderColor: darken("brand.500", 20),
      },
    },
  },
  defaultProps: {},
};
