import { Box, SimpleGrid } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import BackgroundSlider from "../../backgroundSlider/BackgroundSlider";
import Card, { ICard } from "./Card";
import Nav from "../../navigation/Nav";
import ScaleFadeAnimation from "../../animations/ScaleFadeAnimation";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../config/i18n/i18n.constants";
import axios from "axios";
import { BottomRightInfoBlock } from "../../infoblock/BottomRightInfoBlock";

const BASE_URL = "/assets/home/";
const DOCUMENTS_URL = BASE_URL + "documents/";
const IMAGES_URL = BASE_URL + "images/";

interface IHome {
  backgroundImages: Array<string>;
  cards: Array<ICard>;
}

export default function Home() {
  const { t, i18n } = useTranslation(namespaces.pages.home);
  const [home, setHome] = useState<IHome>();

  useEffect(() => {
    document.title = "UNI-CITY Kft. | " + t("title");
    axios
      .get(BASE_URL + "home.json", {
        headers: {
          pragma: "no-cache",
          "cache-control": "no-cache",
        },
      })
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        setHome(response["home"]);
      });
  }, [t, i18n.language]);
  return (
    <>
      <Nav page="home" />
      <Box paddingBottom={16}>
        {home && (
          <BackgroundSlider
            duration={5}
            transition={1}
            images={home.backgroundImages.map((image) => {
              return "/assets/home/images/" + image;
            })}
          />
        )}
        {home && (
          <ScaleFadeAnimation>
            <SimpleGrid
              columns={[1, 2, 1]}
              gap={6}
              maxWidth={["100%", "100%", "175px"]}
              padding={4}
            >
              {home.cards.map((card, index) => {
                return (
                  <Card
                    key={index}
                    card={card}
                    imagesUrl={IMAGES_URL}
                    documentsUrl={DOCUMENTS_URL}
                  />
                );
              })}
            </SimpleGrid>
          </ScaleFadeAnimation>
        )}
      </Box>
      <BottomRightInfoBlock />
    </>
  );
}
