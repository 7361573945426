import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { namespaces } from "../config/i18n/i18n.constants";
import InfoBlock from "../infoblock/InfoBlock";
import HorizontalNav from "./HorizontalNav";
import MobileDrawer from "./MobileDrawer";

type Props = {
  page: string;
};

export interface INav {
  height: string;
  link: string;
  img: string;
}

export default function Nav({ page }: Props) {
  const { i18n } = useTranslation(namespaces.pages.home);
  const changeLanguage = (language: string) => () => {
    i18n.changeLanguage(language);
  };
  const [navData, setNav] = useState<INav | null>(null);
  useEffect(() => {
    axios
      .get("./assets/nav/nav.json", {
        headers: {
          pragma: "no-cache",
          "cache-control": "no-cache",
        },
      })
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        setNav(response["nav"]);
      });
  }, []);
  return (
    <>
      <Box bg="nav">
        {navData && <InfoBlock data={navData} basePath="/assets/nav/" />}
        <Flex h={50} w="100%" px={8} justifyContent="space-between">
          <Box height="100%">
            <Link to="/">
              <Image
                height="100%"
                src={"/assets/nav/unicity.png"}
                padding={2}
              />
            </Link>
          </Box>
          <HorizontalNav current={page} />
          <Box>
            <Center h="100%">
              <Menu closeOnSelect={false}>
                <MenuButton
                  as={Button}
                  variant="link"
                  float="left"
                  width={8}
                  height={8}
                >
                  <Image src={"/assets/icons/" + i18n.language + ".png"} />
                </MenuButton>
                <MenuList>
                  <MenuOptionGroup type="radio" defaultValue={i18n.language}>
                    <MenuItemOption value="hu" onClick={changeLanguage("hu")}>
                      Magyar
                    </MenuItemOption>
                    <MenuItemOption value="en" onClick={changeLanguage("en")}>
                      English
                    </MenuItemOption>
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
              <MobileDrawer current={page} />
            </Center>
          </Box>
        </Flex>
      </Box>
      <Flex
        h={50}
        w="100%"
        px={8}
        mb={4}
        justifyContent="space-between"
        bgGradient="linear(to-b, nav, transparent)"
      ></Flex>
    </>
  );
}
