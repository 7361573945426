import { ComponentStyleConfig } from "@chakra-ui/react";

export const LinkStyles: ComponentStyleConfig = {
  baseStyle: {},
  sizes: {},
  variants: {
    default: {
      color: "brand.200",
      _hover: {
        color: "white",
      },
    },
  },
  defaultProps: {},
};
