import { ComponentStyleConfig } from "@chakra-ui/react";

export const TextareaStyles: ComponentStyleConfig = {
  baseStyle: {},
  sizes: {},
  variants: {
    primary: {
      border: "1px",
      color: "brand.500",
      borderColor: "brand.700",
      background: "bg",
    },
  },
  defaultProps: {},
};
