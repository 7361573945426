import { Box, Center, Heading } from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ScaleFadeAnimation from "../../animations/ScaleFadeAnimation";
import { namespaces } from "../../config/i18n/i18n.constants";
import Nav from "../../navigation/Nav";
import Map from "../services/Map";
import Service from "../services/Service";
import { IService } from "../services/Services";
import { BottomRightInfoBlock } from "../../infoblock/BottomRightInfoBlock";

const BASE_URL = "/assets/references/";
const DOCUMENTS_URL = BASE_URL + "documents/";
const IMAGES_URL = BASE_URL + "images/";
const VIDEOS_URL = BASE_URL + "videos/";

export default function References() {
  const { t, i18n } = useTranslation(namespaces.pages.references);
  const [references, setServices] = useState<Array<IService>>([]);
  useEffect(() => {
    document.title = "UNI-CITY Kft. | " + t("title");
    axios
      .get("./assets/references/references.json", {
        headers: {
          pragma: "no-cache",
          "cache-control": "no-cache",
        },
      })
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        setServices(response["references"]);
      });
  }, [t, i18n.language]);
  return (
    <>
      <Nav page="references" />
      <Center paddingBottom={16}>
        <Box width="80%">
          <ScaleFadeAnimation>
            <Heading textStyle="h1">{t("references")}</Heading>
          </ScaleFadeAnimation>
          <ScaleFadeAnimation>
            <Map
              services={references}
              documentsUrl={DOCUMENTS_URL}
              imagesUrl={IMAGES_URL}
              videosUrl={VIDEOS_URL}
            />
          </ScaleFadeAnimation>
          {references.map((references, index) => {
            return (
              <Service
                hideCoordinates={true}
                key={index}
                service={references}
                documentsUrl={DOCUMENTS_URL}
                imagesUrl={IMAGES_URL}
                videosUrl={VIDEOS_URL}
              />
            );
          })}
        </Box>
      </Center>
      <BottomRightInfoBlock />
    </>
  );
}
