import { Box, Divider, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ScaleFadeAnimation from "../../animations/ScaleFadeAnimation";
import { namespaces } from "../../config/i18n/i18n.constants";
import VideoGallery from "../../videoGallery/VideoGallery";
import Gallery from "../gallery/Gallery";
import { IDevice } from "./Devices";

type Props = {
  device: IDevice;
  imagesUrl: string;
  videosUrl: string;
};

export default function Device({ device, imagesUrl, videosUrl }: Props) {
  const { t, i18n } = useTranslation(namespaces.pages.devices);
  return (
    <ScaleFadeAnimation>
      <Box paddingBottom={4}>
        <Box textStyle="h2">{device.name[i18n.language]}</Box>
        {device.volume?.[i18n.language] && (
          <Text>
            {t("device.volume")} {device.volume[i18n.language]}
          </Text>
        )}
        {device.capacity?.[i18n.language] && (
          <Text>
            {t("device.capacity")} {device.capacity[i18n.language]}
          </Text>
        )}
        {device.siloNumber?.[i18n.language] && (
          <Text>
            {t("device.siloNumber")} {device.siloNumber[i18n.language]}
          </Text>
        )}
        {device.others && (
          <Box paddingTop={4}>
            {device.others[i18n.language].map((other, i) => {
              return <Text key={i}>{other}</Text>;
            })}
          </Box>
        )}
      </Box>
      {device.images && (
        <Box paddingTop={2}>
          <Gallery images={device.images.map((image) => imagesUrl + image)} />
        </Box>
      )}
      {device.images && device.videos && (
        <Divider marginTop={6} borderColor="brand.500" />
      )}
      {device.videos && (
        <Box paddingTop={2}>
          <VideoGallery
            videos={device.videos.map((video) => videosUrl + video)}
          />
        </Box>
      )}
    </ScaleFadeAnimation>
  );
}
