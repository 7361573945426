import { Center, Link, Text, useStyleConfig, VStack } from "@chakra-ui/react";

export interface IPartnerCard {
  image: string;
  name: { [key: string]: string } | null;
  url: string;
}

type Props = {
  card: IPartnerCard;
  imagesUrl: string;
};

export default function PartnerCard({ card, imagesUrl }: Props) {
  const generalStyles = useStyleConfig("Card", { variant: "primary" });
  return (
    <Link target="_blank" href={card.url}>
      <Center
        __css={generalStyles}
        width="100%"
        height="100%"
        cursor="pointer"
        padding={4}
        border="1px solid black"
        borderRadius={4}
      >
        <VStack>
          <img src={imagesUrl + card.image} alt="" />
          <Text paddingTop={2} width="100%" textAlign="center">
            {card.name?.en}
          </Text>
        </VStack>
      </Center>
    </Link>
  );
}
