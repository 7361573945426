import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./config/i18n/i18n";
import { Center, ChakraProvider, extendTheme, Spinner } from "@chakra-ui/react";
import { ButtonStyles } from "./styles/ButtonStyles";
import { CardStyle } from "./styles/CardStyle";
import { NavLinkStyle } from "./styles/NavLinkStyle";
import { InputStyles } from "./styles/InputStyles";
import { TextareaStyles } from "./styles/TextareaStyles";
import "leaflet/dist/leaflet.css";
import { LinkStyles } from "./styles/LinkStyles";

const theme = extendTheme({
  colors: {
    nav: "#D3D3D3",
    bg: "#666666",
    bg08: "rgba(80,80,80,0.8)",
    bg09: "rgba(80,80,80,0.9)",
    primary: "#FEEC00",
    brand: {
      50: "#ffffff",
      100: "#fffbcc",
      200: "#fff899",
      300: "#fff465",
      400: "#fff24c",
      500: "#FEEC00",
      600: "#cbbd00",
      700: "#988e00",
      800: "#665e00",
      900: "#332f00",
    },
  },
  components: {
    Button: ButtonStyles,
    Card: CardStyle,
    NavLink: NavLinkStyle,
    Input: InputStyles,
    Textarea: TextareaStyles,
    Link: LinkStyles,
  },
  breakpoints: {
    sm: "320px",
    md: "768px",
    lg: "1345px",
    xl: "1400px",
    "2xl": "1536px",
  },
  textStyles: {
    h1: {
      color: "brand.100",
      paddingBottom: 4,
      paddingTop: 4,
      fontSize: "62em",
    },
    h2: {
      color: "brand.300",
      paddingBottom: 2,
      paddingTop: 2,
      fontSize: ["1.4em"],
    },
    menu: {
      fontFace: "bold",
      color: "brand.300",
    },
    menuCurrent: {
      color: "brand.50",
    },
  },
  styles: {
    global: {
      body: {
        bg: "#666666",
        color: "brand.600",
        fontSize: "lg",
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Suspense
        fallback={
          <Center height="100vh">
            <Spinner size="xl" />
          </Center>
        }
      >
        <App />
      </Suspense>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
