import { ComponentStyleConfig } from "@chakra-ui/react";

export const NavLinkStyle: ComponentStyleConfig = {
  baseStyle: {},
  sizes: {},
  variants: {
    primary: {
      height: "100%",
      px: 2,
      fontWeight: "semibold",
      fontSize: "1.2em",
      color: "brand.800",
      "&:after": {
        content: '""',
        color: "red",
        width: "100%",
        position: "absolute",
        transform: "scaleX(0)",
        height: "2px",
        bottom: 0,
        left: 0,
        backgroundColor: "brand.800",
        transformOrigin: "bottom right",
        transition: "transform 0.25s ease-out",
      },
      "&:hover:after": {
        transform: "scaleX(1)",
        transformOrigin: "bottom left",
      },
    },
    current: {
      height: "100%",
      px: 2,
      fontWeight: "semibold",
      fontSize: "1.2em",
      color: "black",
      "&:after": {
        content: '""',
        color: "red",
        width: "100%",
        position: "absolute",
        transform: "scaleX(0)",
        height: "2px",
        bottom: 0,
        left: 0,
        backgroundColor: "black",
        transformOrigin: "bottom right",
        transition: "transform 0.25s ease-out",
      },
      "&:hover:after": {
        transform: "scaleX(1)",
        transformOrigin: "bottom left",
      },
    },
  },
  defaultProps: {},
};
