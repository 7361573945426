import { Box, Image, Link } from "@chakra-ui/react";
import React from "react";
import { INav } from "../navigation/Nav";

type Props = {
  data: INav;
  basePath: string;
};

export default function InfoBlock({ data, basePath }: Props) {
  return (
    <Box height={data.height}>
      <Link href={basePath + data.link} target="_blank">
        <Image src={basePath + data.img} h="100%" />
      </Link>
    </Box>
  );
}
