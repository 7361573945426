import { Box, Center, Heading } from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ScaleFadeAnimation from "../../animations/ScaleFadeAnimation";
import { namespaces } from "../../config/i18n/i18n.constants";
import Nav from "../../navigation/Nav";
import Accessory from "./Accessory";
import Device from "./Device";
import { BottomRightInfoBlock } from "../../infoblock/BottomRightInfoBlock";

const BASE_URL = "/assets/devices/";
const IMAGES_URL = BASE_URL + "images/";
const VIDEOS_URL = BASE_URL + "videos/";

export interface IDevice {
  name: { [key: string]: string };
  volume: { [key: string]: string } | null;
  capacity: { [key: string]: string } | null;
  siloNumber: { [key: string]: string } | null;
  others: { [key: string]: Array<string> } | null;
  images: Array<string> | null;
  videos: Array<string> | null;
}

export interface IAccessory {
  name: { [key: string]: string };
  others: { [key: string]: Array<string> } | null;
  images: Array<string> | null;
  videos: Array<string> | null;
}

export default function Devices() {
  const { t, i18n } = useTranslation(namespaces.pages.devices);
  const [devices, setDevices] = useState<Array<IDevice>>([]);
  const [accessories, setAccessories] = useState<Array<IAccessory>>([]);

  useEffect(() => {
    document.title = "UNI-CITY Kft. | " + t("title");

    axios
      .get("./assets/devices/devices.json", {
        headers: {
          pragma: "no-cache",
          "cache-control": "no-cache",
        },
      })
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        setDevices(response["devices"]);
        setAccessories(response["accessories"]);
      });
  }, [t, i18n.language]);
  return (
    <>
      <Nav page="devices" />
      <Center paddingBottom={16}>
        <Box width="80%">
          <ScaleFadeAnimation>
            <Heading textStyle="h1">{t("machines")}</Heading>
          </ScaleFadeAnimation>
          {devices.map((device, index) => {
            return (
              <Device
                key={index}
                device={device}
                imagesUrl={IMAGES_URL}
                videosUrl={VIDEOS_URL}
              />
            );
          })}
          <ScaleFadeAnimation>
            <Heading textStyle="h1">{t("accessories")}</Heading>
          </ScaleFadeAnimation>
          {accessories.map((accessory, index) => {
            return (
              <Accessory
                key={index}
                accessory={accessory}
                imagesUrl={IMAGES_URL}
                videosUrl={VIDEOS_URL}
              />
            );
          })}
        </Box>
      </Center>
      <BottomRightInfoBlock />
    </>
  );
}
