import { Center, GridItem, SimpleGrid } from "@chakra-ui/react";
import { useState } from "react";
import Modal from "./Modal";
import { BottomRightInfoBlock } from "../../infoblock/BottomRightInfoBlock";

type Props = {
  images: Array<string>;
};

export default function Gallery({ images }: Props) {
  const [modal, setModal] = useState(false);
  const [tempImgSrc, setTempImgSrc] = useState("");

  const getImg = (image: string) => {
    setTempImgSrc(image);
    setModal(true);
  };

  return (
    <>
      {modal && (
        <Modal
          image={tempImgSrc}
          setModal={setModal}
          handleRotationLeft={() => {
            const index = images.findIndex((image) => image === tempImgSrc);
            if (index === 0) {
              setTempImgSrc(images[images.length - 1]);
            } else {
              setTempImgSrc(images[index - 1]);
            }
          }}
          handleRotationRight={() => {
            const index = images.findIndex((image) => image === tempImgSrc);
            if (index === images.length - 1) {
              setTempImgSrc(images[0]);
            } else {
              setTempImgSrc(images[index + 1]);
            }
          }}
        />
      )}
      <SimpleGrid columns={[2, 3, 4, 5]} gap={6} paddingTop={4}>
        {images.map((image, index) => {
          return (
            <GridItem key={index} w="100%">
              <Center
                width="100%"
                height="100%"
                cursor="pointer"
                onClick={() => getImg(image)}
              >
                <img src={image} alt={image} />
              </Center>
            </GridItem>
          );
        })}
      </SimpleGrid>
      <BottomRightInfoBlock />
    </>
  );
}
