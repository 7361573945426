import { Box } from "@chakra-ui/react";
import { CloseIcon, ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";

type Props = {
  image: string;
  setModal: Function;
  handleRotationLeft: Function;
  handleRotationRight: Function;
};

export default function Modal({
  image,
  setModal,
  handleRotationLeft,
  handleRotationRight,
}: Props) {
  return (
    <>
      <Box
        width="100%"
        height="100vh"
        position="fixed"
        top={0}
        left={0}
        display="flex"
        justifyContent="center"
        alignItems="center"
        backgroundColor="rgba(0, 0, 0, 0.8)"
        overflow="hidden"
        zIndex={998}
      >
        <img
          src={image}
          alt={image}
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            display: "block",
            lineHeight: 0,
            padding: 20,
          }}
        />
        <CloseIcon
          position="fixed"
          display="flex"
          top={10}
          right={10}
          width={8}
          height={8}
          cursor="pointer"
          onClick={() => setModal(false)}
        />
        <ArrowLeftIcon
          position="fixed"
          display="flex"
          left={10}
          width={8}
          height={8}
          cursor="pointer"
          onClick={() => handleRotationLeft()}
        />
        <ArrowRightIcon
          position="fixed"
          display="flex"
          right={10}
          width={8}
          height={8}
          cursor="pointer"
          onClick={() => handleRotationRight()}
        />
      </Box>
    </>
  );
}
