import { Box, Center, Heading, Text } from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ScaleFadeAnimation from "../../animations/ScaleFadeAnimation";
import { namespaces } from "../../config/i18n/i18n.constants";
import Nav from "../../navigation/Nav";
import { BottomRightInfoBlock } from "../../infoblock/BottomRightInfoBlock";

interface IAbout {
  name: { [key: string]: string };
  description: { [key: string]: Array<string> } | null;
  videoUrl: string;
}

export default function About() {
  const { t, i18n } = useTranslation(namespaces.pages.about);
  const [about, setAbout] = useState<IAbout>();
  useEffect(() => {
    axios
      .get("./assets/about/about.json", {
        headers: {
          pragma: "no-cache",
          "cache-control": "no-cache",
        },
      })
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        setAbout(response["about"]);
      });

    document.title = "UNI-CITY Kft. | " + t("title");
  }, [t, i18n.language]);
  return (
    <>
      <Nav page="about" />
      <Center paddingBottom={16}>
        <Box width="80%">
          <ScaleFadeAnimation>
            <Heading textStyle="h1">{about?.name[i18n.language]}</Heading>
            {about?.description?.[i18n.language].map((item, index) => {
              return (
                <Text paddingBottom={2} key={index}>
                  {item}
                </Text>
              );
            })}
          </ScaleFadeAnimation>

          <ScaleFadeAnimation>
            <Center paddingTop={4} paddingBottom={4}>
              {about && (
                <video
                  controls
                  src={"/assets/about/videos/" + about.videoUrl}
                />
              )}
            </Center>
          </ScaleFadeAnimation>
        </Box>
      </Center>
      <BottomRightInfoBlock />
    </>
  );
}
