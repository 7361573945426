import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  useDisclosure,
  useStyleConfig,
  VStack,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { namespaces } from "../config/i18n/i18n.constants";
import Navlink from "./Navlink";

type Props = {
  current: string;
};

export default function MobileDrawer({ current }: Props) {
  const defaultStyle = useStyleConfig("NavLink", { variant: "default" });
  const currentStyle = useStyleConfig("NavLink", { variant: "current" });
  const { t, i18n } = useTranslation(namespaces.navigation);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  return (
    <Flex display={{ base: "flex", md: "flex", lg: "none" }}>
      <Button onClick={onOpen} variant="text" height="100%">
        <HamburgerIcon width={8} height={8} color="brand.900" />
      </Button>
      <Drawer isOpen={isOpen} onClose={onClose} placement="right">
        <DrawerOverlay />
        <DrawerContent bg="nav">
          <DrawerCloseButton variant="secondary" />
          <DrawerBody
            paddingLeft={0}
            paddingRight={0}
            paddingBottom={0}
            paddingTop={12}
          >
            <VStack alignItems="left" spacing={0} padding={0}>
              <Navlink
                justifyContent="left"
                current={current}
                textKey="home"
                href="/"
              />
              <Navlink
                justifyContent="left"
                current={current}
                textKey="about"
                href="/about"
              />
              <Navlink
                justifyContent="left"
                current={current}
                textKey="devices"
                href="/devices"
              />
              <Navlink
                justifyContent="left"
                current={current}
                textKey="services"
                href="/services"
              />
              <Navlink
                justifyContent="left"
                current={current}
                textKey="concrete_barriers"
                href="/concrete_barriers"
              />
              <Navlink
                justifyContent="left"
                current={current}
                textKey="references"
                href="/references"
              />
              <Navlink
                justifyContent="left"
                current={current}
                textKey="documents"
                href="/documents"
              />
              <Navlink
                justifyContent="left"
                current={current}
                textKey="contact"
                href="/contact"
              />
              <Navlink
                justifyContent="left"
                current={current}
                textKey="partners"
                href="/partners"
              />
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}
