import { ComponentStyleConfig } from "@chakra-ui/react";

export const InputStyles: ComponentStyleConfig = {
  baseStyle: {},
  sizes: {},
  variants: {
    primary: {
      field: {
        border: "1px",
        color: "brand.500",
        borderColor: "brand.700",
        background: "bg",
      },
    },
  },
  defaultProps: {},
};
