import {
  Box,
  Center,
  LinkBox,
  LinkOverlay,
  useStyleConfig,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { namespaces } from "../config/i18n/i18n.constants";

type Props = {
  current: string;
  textKey: string;
  href: string;
  justifyContent: "center" | "left";
};

export default function Navlink({
  current,
  textKey,
  href,
  justifyContent,
}: Props) {
  const defaultStyle = useStyleConfig("NavLink", { variant: "primary" });
  const currentStyle = useStyleConfig("NavLink", { variant: "current" });
  const { t } = useTranslation(namespaces.navigation);

  const getStyle = (style: string) => {
    return style === current ? currentStyle : defaultStyle;
  };
  return (
    <LinkBox h="100%">
      <Center __css={getStyle(textKey)} justifyContent={justifyContent}>
        <LinkOverlay as={Link} to={href}>
          <Box
            h="100%"
            alignContent="left"
            paddingLeft={justifyContent == "left" ? 4 : 0}
          >
            {t(textKey)}
          </Box>
        </LinkOverlay>
      </Center>
    </LinkBox>
  );
}
