export const namespaces = {
  pages: {
    home: "pages.home",
    devices: "pages.devices",
    services: "pages.services",
    references: "pages.references",
    contact: "pages.contact",
    partners: "pages.partners",
    about: "pages.about",
    barriers: "pages.barriers",
    documents: "pages.documents",
  },
  navigation: "navigation",
  common: "common",
};

export const languages = {
  hu: "hu",
  en: "en",
};
