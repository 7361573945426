import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { namespaces } from "../../config/i18n/i18n.constants";
import { MarkerIcon } from "./MarkerIcon";
import Service from "./Service";
import { IService } from "./Services";

type Props = {
  services: Array<IService>;
  documentsUrl: string;
  imagesUrl: string;
  videosUrl: string;
};

const position: [number, number] = [47.156387, 19.102154];

export default function Map({
  services,
  documentsUrl,
  imagesUrl,
  videosUrl,
}: Props) {
  const { t } = useTranslation(namespaces.common);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [asdf, setAsdf] = useState(0);
  const [currentService, setCurrentService] = useState<IService | null>(null);

  const handleClose = () => {
    setCurrentService(null);
    onClose();
  };

  useEffect(() => {
    setAsdf(Math.random());
  }, []);

  return (
    <Box paddingBottom={4}>
      <MapContainer
        whenCreated={(mapInstance) =>
          setTimeout(() => mapInstance.invalidateSize(), 200)
        }
        key={asdf}
        center={position}
        zoom={8}
        scrollWheelZoom={false}
        minZoom={8}
        style={{ height: "80vh", width: "100%" }}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {services
          .filter(
            (service) =>
              service.longitude !== undefined && service.latitude !== undefined
          )
          .map((service, index) => {
            return (
              <Marker
                key={index}
                riseOnHover
                icon={MarkerIcon}
                position={
                  [service.longitude, service.latitude] as [number, number]
                }
                eventHandlers={{
                  click: (e) => {
                    setCurrentService(service);
                    onOpen();
                  },
                }}
              ></Marker>
            );
          })}
      </MapContainer>
      <Modal isOpen={isOpen} onClose={handleClose} size="4xl">
        <ModalOverlay />
        <ModalContent
          backgroundColor="#666666"
          border="1px solid"
          borderColor="primary"
        >
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {currentService && (
              <Service
                hideCoordinates={false}
                service={currentService}
                documentsUrl={documentsUrl}
                imagesUrl={imagesUrl}
                videosUrl={videosUrl}
              />
            )}
          </ModalBody>

          <ModalFooter>
            <Button variant="primary" mr={3} onClick={handleClose}>
              {t("buttons.close")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
