import { Box, Image, Link } from "@chakra-ui/react";

export const BottomRightInfoBlock = () => {
  const path = process.env.PUBLIC_URL;
  const image = "/assets/szechenyi2020euJA.png";
  return (
    <Box
      height={150}
      position="fixed"
      bottom={0}
      right={0}
      bg="transparent"
      zIndex={1000}
    >
      <Link
        href={`${path}/assets/nav/GINOP_PLUSZ-1_2_1-21-2021-02516_sk.pdf`}
        target="_blank"
      >
        <Image src={path + image} h="100%" />
      </Link>
    </Box>
  );
};
