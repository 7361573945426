import { useState, useEffect } from "react";
import { Box, Center, Heading } from "@chakra-ui/react";
import Service from "./Service";
import Map from "./Map";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../config/i18n/i18n.constants";
import Nav from "../../navigation/Nav";
import ScaleFadeAnimation from "../../animations/ScaleFadeAnimation";
import axios from "axios";
import { BottomRightInfoBlock } from "../../infoblock/BottomRightInfoBlock";

const BASE_URL = "/assets/services/";
const DOCUMENTS_URL = BASE_URL + "documents/";
const IMAGES_URL = BASE_URL + "images/";
const VIDEOS_URL = BASE_URL + "videos/";
type Props = {};

export interface IService {
  siteName: { [key: string]: string };
  siteAddress: { [key: string]: string };
  longitude: number | null;
  latitude: number | null;
  email: string | null;
  phone: string | null;
  pricelistUrl: string | null;
  declarationOfPerformanceUrl: string | null;
  images: Array<string> | null;
  videos: Array<string> | null;
}

export default function Services({}: Props) {
  const { t, i18n } = useTranslation(namespaces.pages.services);
  const [services, setServices] = useState<Array<IService>>([]);

  useEffect(() => {
    document.title = "UNI-CITY Kft. | " + t("title");
    axios
      .get("./assets/services/services.json", {
        headers: {
          pragma: "no-cache",
          "cache-control": "no-cache",
        },
      })
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        setServices(response["services"]);
      });
  }, [t, i18n.language]);
  return (
    <>
      <Nav page="services" />
      <Center paddingBottom={16}>
        <Box width="80%">
          <ScaleFadeAnimation>
            <Heading textStyle="h1">{t("concreteProduction")}</Heading>
          </ScaleFadeAnimation>
          <Map
            services={services}
            documentsUrl={DOCUMENTS_URL}
            imagesUrl={IMAGES_URL}
            videosUrl={VIDEOS_URL}
          />
          {services.map((service, index) => {
            return (
              <Service
                hideCoordinates={false}
                key={index}
                service={service}
                documentsUrl={DOCUMENTS_URL}
                imagesUrl={IMAGES_URL}
                videosUrl={VIDEOS_URL}
              />
            );
          })}
        </Box>
      </Center>

      <BottomRightInfoBlock />
    </>
  );
}
