import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ScaleFadeAnimation from "../../animations/ScaleFadeAnimation";
import { namespaces } from "../../config/i18n/i18n.constants";
import Nav from "../../navigation/Nav";
import { BottomRightInfoBlock } from "../../infoblock/BottomRightInfoBlock";

interface IContact {
  name: { [key: string]: string };
  sortName: { [key: string]: string };
  address: { [key: string]: string };
  taxNumber: string;
  email: string;
  phone: string;
}

interface IFormData {
  name: string;
  email: string;
  phone: string;
  message: string;
}

export default function Contact() {
  const { t, i18n } = useTranslation(namespaces.pages.contact);
  const [contact, setContact] = useState<IContact>();
  const [formData, setFormData] = useState<IFormData>({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    field: string
  ) => {
    const value = e.target.value;
    setFormData({ ...formData, [field]: value });
  };

  useEffect(() => {
    document.title = "UNI-CITY Kft. | " + t("title");
    axios
      .get("./assets/contact/contact.json", {
        headers: {
          pragma: "no-cache",
          "cache-control": "no-cache",
        },
      })
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        setContact(response["contact"]);
      });
  }, [t, i18n.language]);
  return (
    <>
      <Nav page="contact" />
      <Center paddingBottom={16}>
        <Box width="80%">
          <ScaleFadeAnimation>
            <HStack width="100%" justifyContent="space-evenly" py={4}>
              <Box>
                <Text paddingBottom={4} fontSize="3xl">
                  {contact && contact.name[i18n.language]}
                </Text>
                <Text paddingBottom={4}>
                  {contact && contact.sortName[i18n.language]}
                </Text>
                <Text>
                  {t("common:address")}{" "}
                  {contact && contact.address[i18n.language]}
                </Text>
                <Text>
                  {t("taxNumber")} {contact && contact.taxNumber}
                </Text>
                <Text>
                  {t("common:email")} {contact && contact.email}
                </Text>
                <Text>
                  {t("common:phone")} {contact && contact.phone}
                </Text>
              </Box>
              <Image src="/assets/contact/images/iroda.jpg" maxH={300} />
            </HStack>
          </ScaleFadeAnimation>
          <ScaleFadeAnimation>
            <Box height="70vh" width="100%">
              <iframe
                title="map"
                width="100%"
                height="100%"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2694.938823707043!2d19.103239314883652!3d47.51058260260034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741db62a3a120a1%3A0xcd381f3d330e333f!2sBudapest%2C+Szugl%C3%B3+u.+9-15%2C+1145!5e0!3m2!1shu!2shu!4v1524077582938"
              ></iframe>
            </Box>
          </ScaleFadeAnimation>
          <ScaleFadeAnimation>
            <Center width="100%">
              <form action="mail.php" method="POST" style={{ width: "100%" }}>
                <FormLabel py={4}>{t("contactMail")}</FormLabel>
                <FormControl>
                  <Input
                    variant="primary"
                    id="name"
                    name="name"
                    marginY={2}
                    isRequired
                    py={4}
                    placeholder={t("form.name")}
                    onChange={(e) => handleChange(e, "name")}
                  />
                </FormControl>
                <FormControl>
                  <Input
                    variant="primary"
                    id="email"
                    name="email"
                    marginY={2}
                    isRequired
                    py={4}
                    placeholder={t("form.email")}
                    onChange={(e) => handleChange(e, "email")}
                  />
                </FormControl>
                <FormControl>
                  <Input
                    variant="primary"
                    id="phone"
                    name="phone"
                    marginY={2}
                    isRequired
                    py={4}
                    placeholder={t("form.phone")}
                    onChange={(e) => handleChange(e, "phone")}
                  />
                </FormControl>
                <FormControl>
                  <Textarea
                    variant="primary"
                    id="message"
                    name="message"
                    marginY={2}
                    py={4}
                    isRequired
                    placeholder={t("form.message")}
                    style={{ resize: "none" }}
                    onChange={(e) => handleChange(e, "message")}
                  />
                </FormControl>
                <HStack justifyContent="space-between" width="100%" py={2}>
                  <Button variant="secondary" type="reset">
                    {t("button.cancel")}
                  </Button>
                  <Button variant="primary" type="submit">
                    {t("button.send")}
                  </Button>
                </HStack>
              </form>
            </Center>
          </ScaleFadeAnimation>
        </Box>
      </Center>
      <BottomRightInfoBlock />
    </>
  );
}
