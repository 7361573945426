import { Box, Center, Heading } from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ScaleFadeAnimation from "../../animations/ScaleFadeAnimation";
import { namespaces } from "../../config/i18n/i18n.constants";
import { BottomRightInfoBlock } from "../../infoblock/BottomRightInfoBlock";
import Nav from "../../navigation/Nav";
import { IDocument } from "../concreteBarriers/ConcreteBarriers";
import Document from "../concreteBarriers/Document";

export default function Documents() {
  const { t, i18n } = useTranslation(namespaces.pages.documents);
  const [documents, setDocuments] = useState<Array<IDocument>>([]);
  useEffect(() => {
    document.title = "UNI-CITY Kft. | " + t("title");
    axios
      .get("./assets/documents/documents.json", {
        headers: {
          pragma: "no-cache",
          "cache-control": "no-cache",
        },
      })
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        setDocuments(response["documents"]);
      });
  }, [t, i18n.language]);

  return (
    <>
      <Nav page="documents" />
      <Center paddingBottom={16}>
        <Box width="80%">
          <ScaleFadeAnimation>
            <Heading textStyle="h1">{t("title")}</Heading>
          </ScaleFadeAnimation>
          <ScaleFadeAnimation>
            {documents.map((document, index) => {
              return <Document document={document} key={index} />;
            })}
          </ScaleFadeAnimation>
        </Box>
      </Center>
      <BottomRightInfoBlock />
    </>
  );
}
