import { Box, Center, SimpleGrid } from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ScaleFadeAnimation from "../../animations/ScaleFadeAnimation";
import { namespaces } from "../../config/i18n/i18n.constants";
import Nav from "../../navigation/Nav";
import Card, { ICard } from "../home/Card";
import PartnerCard, { IPartnerCard } from "./PartnerCard";
import { BottomRightInfoBlock } from "../../infoblock/BottomRightInfoBlock";

const BASE_URL = "/assets/partners/";
const IMAGES_URL = BASE_URL + "images/";

const convertToLocalCard = (partnerCard: IPartnerCard) => {
  const card: ICard = {
    description: partnerCard.name,
    longDescription: null,
    redirect: null,
    url: partnerCard.image,
  };
  return card;
};

export default function Partners() {
  const { t, i18n } = useTranslation(namespaces.pages.partners);
  const [partners, setHome] = useState<Array<IPartnerCard>>();
  useEffect(() => {
    document.title = "UNI-CITY Kft. | " + t("title");
    axios
      .get("./assets/partners/partners.json", {
        headers: {
          pragma: "no-cache",
          "cache-control": "no-cache",
        },
      })
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        setHome(response["partners"]);
      });
  }, [t, i18n.language]);
  return (
    <>
      <Nav page="partners" />
      <Center paddingBottom={16}>
        <Box width="80%">
          <ScaleFadeAnimation>
            <SimpleGrid columns={[1, 2, 3, 4, 5]} gap={6}>
              {partners &&
                partners.map((card, index) => {
                  return card.url ? (
                    <PartnerCard
                      key={index}
                      card={card}
                      imagesUrl={IMAGES_URL}
                    />
                  ) : (
                    <Card
                      key={index}
                      card={convertToLocalCard(card)}
                      imagesUrl={IMAGES_URL}
                      documentsUrl=""
                    />
                  );
                })}
            </SimpleGrid>
          </ScaleFadeAnimation>
        </Box>
      </Center>
      <BottomRightInfoBlock />
    </>
  );
}
