import { Box, Link } from "@chakra-ui/react";
import React from "react";
import { i18n } from "../../config/i18n/i18n";
import { IDocument } from "../concreteBarriers/ConcreteBarriers";

type Props = {
  document: IDocument;
};

export default function Document({ document }: Props) {
  return (
    <Box>
      <Link variant="default" download={true} href={document.url}>
        {document.text[i18n.language]}
      </Link>
    </Box>
  );
}
