import { HashRouter, Routes, Route } from "react-router-dom";
import Services from "./pages/services/Services";
import Devices from "./pages/devices/Devices";
import Contact from "./pages/contact/Contact";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import References from "./pages/references/References";
import Partners from "./pages/partners/Partners";
import ConcreteBarriers from "./pages/concreteBarriers/ConcreteBarriers";
import { BottomRightInfoBlock } from "./infoblock/BottomRightInfoBlock";
import Documents from "./pages/documents/Documents";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/devices" element={<Devices />} />
        <Route path="/services" element={<Services />} />
        <Route path="/concrete_barriers" element={<ConcreteBarriers />} />
        <Route path="/references" element={<References />} />
        <Route path="/documents" element={<Documents />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/partners" element={<Partners />} />
      </Routes>
      <BottomRightInfoBlock />
    </HashRouter>
  );
}

export default App;
