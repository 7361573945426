import { HStack, VStack } from "@chakra-ui/react";
import InfoBlock from "../infoblock/InfoBlock";
import Navlink from "./Navlink";

type Props = {
  current: string;
};

export default function HorizontalNav({ current }: Props) {
  return (
    <HStack
      spacing={0}
      height="100%"
      padding={0}
      display={{ base: "none", md: "none", lg: "flex" }}
    >
      <Navlink
        justifyContent="center"
        current={current}
        textKey="home"
        href="/"
      />
      <Navlink
        justifyContent="center"
        current={current}
        textKey="about"
        href="/about"
      />
      <Navlink
        justifyContent="center"
        current={current}
        textKey="devices"
        href="/devices"
      />
      <Navlink
        justifyContent="center"
        current={current}
        textKey="services"
        href="/services"
      />
      <Navlink
        justifyContent="center"
        current={current}
        textKey="concrete_barriers"
        href="/concrete_barriers"
      />
      <Navlink
        justifyContent="center"
        current={current}
        textKey="references"
        href="/references"
      />
      <Navlink
        justifyContent="center"
        current={current}
        textKey="documents"
        href="/documents"
      />
      <Navlink
        justifyContent="center"
        current={current}
        textKey="contact"
        href="/contact"
      />
      <Navlink
        justifyContent="center"
        current={current}
        textKey="partners"
        href="/partners"
      />
    </HStack>
  );
}
