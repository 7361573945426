import { FaPlay, FaBeer } from "react-icons/fa";
import { Box, Center, GridItem, SimpleGrid } from "@chakra-ui/react";
import { useState } from "react";
import Modal from "./Modal";

type Props = {
  videos: Array<string>;
};

export default function VideoGallery({ videos }: Props) {
  const [modal, setModal] = useState(false);
  const [tempImgSrc, setTempImgSrc] = useState("");

  const getImg = (image: string) => {
    setTempImgSrc(image);
    setModal(true);
  };
  return (
    <>
      {modal && (
        <Modal
          image={tempImgSrc}
          setModal={setModal}
          handleRotationLeft={() => {
            const index = videos.findIndex((video) => video === tempImgSrc);
            if (index === 0) {
              setTempImgSrc(videos[videos.length - 1]);
            } else {
              setTempImgSrc(videos[index - 1]);
            }
          }}
          handleRotationRight={() => {
            const index = videos.findIndex((video) => video === tempImgSrc);
            if (index === videos.length - 1) {
              setTempImgSrc(videos[0]);
            } else {
              setTempImgSrc(videos[index + 1]);
            }
          }}
        />
      )}
      <SimpleGrid columns={[2, 3, 4, 5]} gap={6} paddingTop={4}>
        {videos.map((video, index) => {
          return (
            <GridItem key={index} w="100%">
              <Center
                width="100%"
                height="100%"
                cursor="pointer"
                onClick={() => getImg(video)}
                position="relative"
              >
                <video
                  style={{
                    zIndex: 1,
                  }}
                  src={video}
                />
                <Box position="absolute" zIndex={2}>
                  <FaPlay size={64} />
                </Box>
              </Center>
            </GridItem>
          );
        })}
      </SimpleGrid>
    </>
  );
}
