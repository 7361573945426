import { ComponentStyleConfig } from "@chakra-ui/react";
import { darken } from "@chakra-ui/theme-tools";

export const CardStyle: ComponentStyleConfig = {
  baseStyle: {},
  sizes: {},
  variants: {
    primary: {
      backgroundColor: "bg08",
      transitionDuration: "0.2s",
      border: "1px solid",
      borderColor: "primary",
      cursor: "pointer",
      padding: 4,
      borderRadius: 4,
      _hover: {
        backgroundColor: "bg09",
        borderColor: darken("primary", 20),
      },
    },
  },
  defaultProps: {},
};
