import { Box, Divider, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ScaleFadeAnimation from "../../animations/ScaleFadeAnimation";
import { namespaces } from "../../config/i18n/i18n.constants";
import VideoGallery from "../../videoGallery/VideoGallery";
import Gallery from "../gallery/Gallery";
import { IAccessory } from "./Devices";

type Props = {
  accessory: IAccessory;
  imagesUrl: string;
  videosUrl: string;
};

export default function Accessory({ accessory, imagesUrl, videosUrl }: Props) {
  const { t, i18n } = useTranslation(namespaces.pages.devices);
  return (
    <ScaleFadeAnimation>
      <Box>
        <Box textStyle="h2">{accessory.name[i18n.language]}</Box>
        {accessory.others && (
          <Box paddingTop={4}>
            {accessory.others[i18n.language].map((other, i) => {
              return <Text key={i}>{other}</Text>;
            })}
          </Box>
        )}
      </Box>
      {accessory.images && (
        <Box paddingTop={2}>
          <Gallery
            images={accessory.images.map((image) => imagesUrl + image)}
          />
        </Box>
      )}
      {accessory.images && accessory.videos && (
        <Divider marginTop={6} borderColor="brand.500" />
      )}
      {accessory.videos && (
        <Box paddingTop={2}>
          <VideoGallery
            videos={accessory.videos.map((video) => videosUrl + video)}
          />
        </Box>
      )}
    </ScaleFadeAnimation>
  );
}
