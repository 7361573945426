import { useEffect } from "react";
import { generateStyleSheet } from "./generateStylesheet";
import injectCss from "./injectCss";

const NAME = "ReactBackgroundSlider";

type Props = {
  images: Array<string>;
  duration: number;
  transition: number;
};

export default function BackgroundSlider({
  images,
  duration,
  transition,
}: Props) {
  useEffect(() => {
    injectCss(
      generateStyleSheet({ imagesCount: images.length, duration, transition }),
      NAME
    );
  }, []);
  return (
    <div id={NAME}>
      {images.map((img, key) => (
        <figure
          key={key}
          style={{
            backgroundImage: `url(${img})`,
            animationDelay: `${(duration + transition) * key}s`,
          }}
        />
      ))}
    </div>
  );
}
