import {
  Button,
  Center,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useStyleConfig,
  VStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../config/i18n/i18n.constants";

export interface ICard {
  url: string;
  description: { [key: string]: string } | null;
  longDescription: { [key: string]: Array<string> } | null;
  redirect: string | null;
}

type Props = {
  card: ICard;
  imagesUrl: string;
  documentsUrl: string;
};

export default function Card({ card, imagesUrl, documentsUrl }: Props) {
  const generalStyles = useStyleConfig("Card", { variant: "primary" });
  const { t, i18n } = useTranslation(namespaces.pages.home);
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Center __css={generalStyles} onClick={onOpen}>
        <VStack>
          <img src={imagesUrl + card.url} />
          {card.description && (
            <Text paddingTop={2} width="100%" textAlign="center">
              {card.description[i18n.language]}
            </Text>
          )}
        </VStack>
      </Center>
      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalContent
          backgroundColor="#666666"
          border="1px solid"
          borderColor="primary"
        >
          <ModalHeader color="primary">
            {card.description?.[i18n.language]}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <VStack>
                <img src={imagesUrl + card.url} />
                {card.redirect && (
                  <Link target="_blank" href={documentsUrl + card.redirect}>
                    {t("viewDocument")}
                  </Link>
                )}
                {card.longDescription?.[i18n.language].map(
                  (description, index) => {
                    return (
                      <Text key={index} width="100%">
                        {description}
                      </Text>
                    );
                  }
                )}
              </VStack>
            </Center>
          </ModalBody>

          <ModalFooter>
            <Button variant="primary" onClick={onClose}>
              {t("common:buttons.close")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
